import { systemConfigService } from '@/api';
import { messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SystemConfig extends Vue {
  public lodopForm = {
    port: ''
  };
  public lodopFormRules = {
    port: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('systemConfig.inputLodopPort')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public created(): void {
    this.getLodopPort();
  }

  public saveLodopConfig(): void {
    (this.$refs.lodopForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      systemConfigService
        .saveLodopPort(this.lodopForm.port)
        .then(() => {
          Message.success(translation('operationRes.saveSuccess'));
        })
        .catch(error => {
          messageError(error);
        });
    });
  }

  private getLodopPort(): void {
    systemConfigService
      .getLodopPort()
      .then(res => {
        this.lodopForm.port = res;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
